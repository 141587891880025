import { AudiencesQuintile, Environment, Geoboundary, Metadata } from '@workspaces/types'
import { AssetDataGeoJsonLayer } from './asset.type'
import { Filter } from './filter.type'
import { FilterAppliedResult } from './plan.type'
import AudiencesQuintileService from '@/services/audiences-quantile.service'

export function isAudiencesQuintileInFilter(filter: Filter): boolean {
  if (!filter.audiencesQuintile) {
    return false
  }
  if (filter.audiencesQuintile.audiences.length === 0) {
    return false
  }
  if (filter.audiencesQuintile.quintiles.length === 0) {
    throw new Error('🛑 Quintile not found in filter. This should not happen')
  }
  return true
}

export function isGeoboundaryFilterReadyForAudiencesQuintile(
  filter: Filter,
): boolean {
  const geoboundariesFilter = filter.geoboundaries
  const region2Literal = 'dmas'

  const allGeoboundaries: Geoboundary.AssetFilterGeoboundaryContent[] = Object.values(geoboundariesFilter).flat()

  if (allGeoboundaries.length === 0) {
    return false
  }

  const type2and4Geoboundaries = allGeoboundaries.filter((geoboundary) =>
    (geoboundary.type === region2Literal || geoboundary.type === 'cbsa') && !geoboundary.exclude
  )

  if (type2and4Geoboundaries.length !== allGeoboundaries.length) {
    return false
  }

  const region2Geoboundaries = type2and4Geoboundaries.filter(
    (geoboundary) => geoboundary.type === region2Literal
  )

  // Solo hay elementos de tipo 2 si los de tipo 2 son iguales a los de tipo 4 y 2
  if (region2Geoboundaries.length === type2and4Geoboundaries.length) {
    return true
  }

  // Solo hay elementos de tipo 4 si los de tipo 2 están vacíos y el filtro por 2 y 4 es mayor que 0
  if (region2Geoboundaries.length === 0) {
    return true
  }

  return false
}

export function getGeoboundaryFromFilter(
  filter: Filter,
): AudiencesQuintile.Params {
  const geoboundariesInFilter = filter.geoboundaries
  if (!geoboundariesInFilter) {
    throw new Error('🛑 Geoboundaries not found in filter')
  }

  // Region2 is DMA
  const dmas = geoboundariesInFilter.region_2
  if (dmas && dmas.length) {
    return {
      dma: dmas.map((dma) => dma.name),
    }
  }

  // Region 4 is CBSA
  const cbsas = geoboundariesInFilter.region_4
  if (cbsas && cbsas.length) {
    return {
      cbsa: cbsas.map((cbsa) => cbsa.name),
    }
  }

  throw new Error('🛑 Geoboundaries not found in filter')
}

export function getParamsFromFilter(
  filter: Filter,
): AudiencesQuintile.RunFilterParams {
  const geoboundary = getGeoboundaryFromFilter(filter)
  const audiencesQuintile = filter.audiencesQuintile
  return {
    geoboundary,
    audiencesQuintile,
  }
}

export async function filterAssetsByAudiencesQuintile(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  assets: AssetDataGeoJsonLayer[],
  filter: Filter,
): Promise<FilterAppliedResult> {
  if (!isAudiencesQuintileInFilter(filter) || !isGeoboundaryFilterReadyForAudiencesQuintile(filter)) {
    return { assets, filterApplied: false }
  }

  const params = getParamsFromFilter(filter)
  const filteredAssetsIds = await AudiencesQuintileService.getAssetsToShow(
    meta,
    environment,
    params,
  )

  const filteredAssetsIdsAsMap = new Map(
    filteredAssetsIds.map((id) => [id, true]),
  )
  const filteredAssets = assets.filter((asset) =>
    filteredAssetsIdsAsMap.has(asset.properties.id),
  )

  return { assets: filteredAssets, filterApplied: true }
}
