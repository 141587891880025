export interface LayerObject {
  properties: {
    name: number
  }
}

// export const quintileColors: string[] = [
//   'rgb(4, 73, 21)', // Quintile 1
//   'rgb(86, 148, 96)', // Quintile 2
//   'rgb(47, 132, 68)', // Quintile 3
//   'rgb(170, 242, 170)', // Quintile 4
//   'rgb(203, 243, 210)', // Quintile 5
// ]

// export const lineColor = {
//   selected: '#rgb(242, 111, 33)',
//   unSelected: '#rgb(255, 255, 255)'
// }

export const QuintileColors: Uint8Array[] = [
  new Uint8Array([4, 73, 21, 200]), // Quintile 1
  new Uint8Array([86, 148, 96, 200]), // Quintile 2
  new Uint8Array([47, 132, 68, 200]), // Quintile 3
  new Uint8Array([170, 242, 170, 200]), // Quintile 4
  new Uint8Array([203, 243, 210, 200]), // Quintile 5
]

export const TransparentColor = new Uint8Array([0, 0, 0, 0])

export const LineColors = {
  selected: new Uint8Array([242, 111, 33, 255]),
  unSelected: new Uint8Array([255, 255, 255, 255]),
}
