import {
  getParamsFromFilter,
  isAudiencesQuintileInFilter,
} from '@/plan/filter/audiences-quintile.helper'
import { Filter } from '@/plan/filter/filter.type'
import AudiencesQuintileBrowserService from '@/services/audiences-quantile-browser.service'
import { Environment, Metadata } from '@workspaces/types'
import { propsForAudiencesQuintileLayer } from '@/layers/audiences-quintile/audiences-quintile-layer'
import layerService from '@/layers/layerService'
import { AUDIENCES_QUINTILE_TILESET_LAYER } from '@/layers/constant'
import { isNotEmptyArray } from './array.helper'
import { LayerObject } from '@/layers/audiences-quintile/audiences-quintile-layer.types'
import {
  getFilledColor,
  getLineWidth,
  getLineColor,
  filterElements,
} from '@/layers/audiences-quintile/audiences-quintile-layer.helper'

function getDataRelatedProps(
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
): any {
  return {
    zipCodesByQuintiles,
    selectedQuintiles,
    getFillColor: (e: LayerObject) => getFilledColor(e, zipCodesByQuintiles),
    getLineWidth: (e: LayerObject) =>
      getLineWidth(e, zipCodesByQuintiles, selectedQuintiles),
    getLineColor: (e: LayerObject) =>
      getLineColor(e, zipCodesByQuintiles, selectedQuintiles),
    getFilterValue: (e: LayerObject) => filterElements(e, zipCodesByQuintiles),
    updateTriggers: {
      getLineColor: [zipCodesByQuintiles, selectedQuintiles],
      getLineWidth: [zipCodesByQuintiles, selectedQuintiles],
      getFillColor: [zipCodesByQuintiles],
    },
  }
}

function createAudiencesQuintileLayer(
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
) {
  layerService.addLayer(
    {
      ...propsForAudiencesQuintileLayer,
      ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
    },
    true,
  )
}

function updateAudiencesQuitileLayer(
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
) {
  const layerProps = layerService.getLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
  if (!layerProps) {
    throw new Error(
      '🛑 Audiences-quintile layer is not found when trying to update',
    )
  }

  layerService.updateLayer(AUDIENCES_QUINTILE_TILESET_LAYER, {
    ...getDataRelatedProps(zipCodesByQuintiles, selectedQuintiles),
  })
}

function addOrUpdateAudienceLayer(
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
): void {
  console.debug('Adding or updating audience quintile layer')
  const isLayerAlreadyAddedToMap = layerService.hasLayer(
    AUDIENCES_QUINTILE_TILESET_LAYER,
  )
  if (!isLayerAlreadyAddedToMap) {
    console.debug('Creating audiences quintile layer')
    createAudiencesQuintileLayer(zipCodesByQuintiles, selectedQuintiles)
  } else {
    console.debug('Updating audiences quintile layer')
    updateAudiencesQuitileLayer(zipCodesByQuintiles, selectedQuintiles)
  }
}

export function removeAudiencesQuintileLayer(): void {
  const isLayerAlreadyAddedToMap = layerService.hasLayer(
    AUDIENCES_QUINTILE_TILESET_LAYER,
  )
  if (isLayerAlreadyAddedToMap) {
    layerService.removeLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
  }
}

export async function setupAudiencesQuintileLayer(
  meta: Metadata.AppMetadata,
  environment: Environment.EnvironmentResolver,
  filter: Filter,
): Promise<void> {
  if (!isAudiencesQuintileInFilter(filter)) {
    return
  }

  const params = getParamsFromFilter(filter)
  const zipCodesByQuintiles =
    await AudiencesQuintileBrowserService.getZipCodesByQuintile(
      meta,
      environment,
      params,
    )

  const selectedQuintiles = params.audiencesQuintile.quintiles
  if (!isNotEmptyArray(selectedQuintiles)) {
    throw new Error(
      'Quintiles selection is empty when trying to render Audiences-quintile layer',
    )
  }

  addOrUpdateAudienceLayer(zipCodesByQuintiles, selectedQuintiles)
}

export function isAudienceQuintileLayerInstantianedInDeckGl(
  checkVisibility?: boolean,
): boolean {
  const layer = layerService.hasLayer(AUDIENCES_QUINTILE_TILESET_LAYER)
  if (checkVisibility === undefined || checkVisibility === false) {
    return layer
  }
  const isVisible = layerService.isVisible(AUDIENCES_QUINTILE_TILESET_LAYER)
  return layer && isVisible
}
