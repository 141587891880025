import {
  LayerObject,
  TransparentColor,
  QuintileColors,
  LineColors,
} from './audiences-quintile-layer.types'

export function filterElements(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, number>,
) {
  const zipCodeValue = object.properties.name
  const isZipcodeInData = zipCodesByQuintiles.has(zipCodeValue)
  if (isZipcodeInData) {
    return 1
  } else {
    return 0
  }
}

export function getFilledColor(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, number>,
): Uint8Array {
  const zipCodeValue = object.properties.name
  const quintile = zipCodesByQuintiles.get(zipCodeValue)
  if (!quintile) {
    return TransparentColor
  } else {
    return QuintileColors[quintile - 1]
  }
}

export function getLineWidth(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
) {
  const zipCodeValue = object.properties.name
  const quintile = zipCodesByQuintiles.get(zipCodeValue)
  if (!quintile) {
    return 0
  } else if (selectedQuintiles.includes(quintile)) {
    return 34
  } else {
    return 0
  }
}

export function getLineColor(
  object: LayerObject,
  zipCodesByQuintiles: Map<number, number>,
  selectedQuintiles: number[],
): Uint8Array {
  const zipCodeValue = object.properties.name
  const quintile = zipCodesByQuintiles.get(zipCodeValue)
  if (!quintile) {
    return new Uint8Array([0, 0, 0, 0])
  } else if (selectedQuintiles.includes(quintile)) {
    return LineColors.selected
  } else {
    return LineColors.unSelected
  }
}
